import React from "react";
import { Container, Alert } from "react-bootstrap";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
const ExpiredCampaign = (props) => {
	const config = useSelector((state) => state.app.config);
	const history = useHistory();
	React.useEffect(() => {
		if (config.campaignState !== "expired") {
			history.push("/");
		}
	}, [config]);
	return (
		<Container>
			<Alert variant="danger" className="text-center" style={{ marginTop: "20%" }}>
				<Alert.Heading>
					<Trans>Sorry</Trans>!
				</Alert.Heading>
				<p className="text-center">
					<Trans>The promotion has been ended on</Trans>
				</p>
				<p className="text-center">{config.activationEnd ? new Date(config.activationEnd).toLocaleString() : ""}</p>
			</Alert>
		</Container>
	);
};

export default ExpiredCampaign;
